// @ts-nocheck
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import 'animate.css/animate.min.css';
import styled from 'styled-components';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Title from '../components/Title';
import heroImage from '../images/banner-4.jpg';


const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const H2 = styled.h2`
  color: #004165;
  font-size: 1.8em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1em;
`;
const H3 = styled.h3`
  color: #004165;
  font-size: 1.5em;
  text-align: center;
  margin-bottom: 1em;
  margin-top: 1em;
  font-weight: 300;
`;

const H4 = styled.h4`
  font-weight: bold;
  font-size: 1.1em;
  color: #004165;
`;

const ParagraphContainer = styled(Row)`
  padding: 1.5em;
  & li {
    padding-bottom: 0.5em;
  }
`;

const PrivacyPolicyPage = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <Wrapper className="container-fluid">
      <Title imageUrl={heroImage} text="Privacy Policy" />
      <ParagraphContainer>
        <Col sm={12} lg={8} className="mx-auto">
          <H2>MyStay ENGLISH Privacy Policy</H2>
          <p>
            Note: This policy is subject to change at any time. Please check all
            MyStay ENGLISH policies on a regular basis for updates.
          </p>
          <p>
            The MyStay ENGLISH is an initiative of MyStay International Pty
            Ltd., a company registered in Australia.
          </p>
          <p>
            This policy has been designed and implemented for users of MyStay
            ENGLISH.
          </p>
          <p>
            This privacy policy describes how the MyStay ENGLISH and its
            subsidiaries and licensees/franchisees and affiliated entities,
            manage the personal information (“PI”) you provide and are referred
            to under the term “MyStay ENGLISH” within this policy.
          </p>
          <p>
            MyStay ENGLISH provides language mentoring support services to
            hosts, students/guests/users, agents, educators and other persons
            involved in the international education industry (“Services”)
            through various channels, including via our website/s and the
            Homestay Management System accessible through the Website/s.
          </p>
          <H3>Personal Information (“PI”), What PI do we collect?</H3>
          <p>
            The type of PI we collect depends on your relationship with us, as
            follows:
          </p>
          <p>
            <strong>Public:</strong> if you submit a query via our online
            ‘contact us’ form, or otherwise email or call MyStay ENGLISH, we may
            collect your name, email address, phone number, address and
            location.
          </p>
          <p>
            <strong>Registration:</strong> when you register with us as a host,
            student/guest/user, educator or agent, we may collect your name,
            your chosen username and password, email address, mailing address
            and profile picture (should you choose to provide one). Other
            information about you may be required to facilitate your service
            requirements.
          </p>
          <H3>What other information do we collect?</H3>
          <p>
            <strong>Log Files:</strong> you visit any of our websites, our
            servers automatically gather information from your browser (such as
            your IP addresses, browser type, Internet service provider (ISP),
            referring/exit pages, platform type, date/time stamp, and number of
            clicks) to analyse trends, administer the site, prevent fraud, track
            visitor movement in the aggregate, and gather broad demographic
            information. For example, we may log your IP address for system
            administration purposes. IP addresses are logged to track a user’s
            session. This gives us an idea of which parts of our site users are
            visiting. We do not share the log files externally.
          </p>
          <p>
            <strong>Purchase Information.</strong> To process purchases, we will
            require your name, address, phone number, email address and credit
            card information. Such information is used to process your order or
            as otherwise described herein. Credit card transactions are
            processed through a PCI Level 1-certified industry leading provider,
            which only uses your information for that purpose.
          </p>
          <H3>
            How your “PI” is collected, why is it needed and how is it used?
          </H3>
          <H4>How we collect your PI</H4>
          <p>
            We collect your PI as part of offering or providing our Services and
            answering your questions. This includes PI you provide through our
            Website (e.g. via our online contact us form, or when you register
            an account with us) and during telephone calls. Generally, we
            collect your PI directly from you but may also collect your PI from
            other people or organisations if you have given consent for them to
            do so (e.g. an Education Agent for student/guest/users)
          </p>

          <H4>Why do we need your PI?</H4>
          <p>We need your PI to provide you with our Services which include:</p>
          <ul>
            <li>
              assessing your eligibility and monitoring your compliance with
              host or student/guest/user (as relevant) contractual obligations;
            </li>
            <li>
              providing you and other persons involved in your arrangement with
              the Services;
            </li>
            <li>
              administering your account, including facilitating matters in
              relation to payment, insurance, and complaints;
            </li>
            <li>
              informing you (including by direct marketing) about our Services
              and the goods and/or services of third parties available in
              connection with our Services;
            </li>
            <li>creating and updating our database(s);</li>
            <li>
              ensuring compliance with our contractual and other legal
              obligations to you;
            </li>
            <li>
              monitoring your use of the Website and contacting you about
              changes to the Website or for customer service purposes;
            </li>
            <li>
              administering our relationship with you by responding to your
              enquiries; and
            </li>
            <li>any other act we are required or authorised to do by law,</li>
          </ul>
          <p>
            These are referred to as the “<strong>Main Purposes</strong>”.
          </p>
          <p>
            If you do not provide us with your PI, we may not be able to carry
            out some or all of the Main Purposes.
          </p>
          <H4>How do we use your PI?</H4>
          <p>
            By submitting your PI to us, you consent to us using your PI for the
            Main Purposes or any purpose that could be reasonably expected at
            the time your PI was collected. We may also disclose your PI to
            agencies, service providers and other businesses (including those
            owned by us) to help us do these things.
          </p>
          <p>
            <strong>We may also use your PI:</strong>
          </p>
          <ul>
            <li>
              for any purpose disclosed to you in an information collection
              statement at the point where we collect your PI; or
            </li>
            <li>
              for a purpose related or ancillary to one of the Main Purposes.
            </li>
          </ul>
          <p>
            We may use certain non-personally identifiable information (such as
            anonymous usage data, IP addresses, browser type etc) to improve the
            quality and design of our Website, and to create new features,
            promotions, functionality and services by storing, tracking,
            analysing and processing user preferences and trends as well as user
            activity and communications.
          </p>
          <H4>Can you remain anonymous or use a pseudonym?</H4>
          <p>
            We will, if practicable, allow you to use a pseudonym or to not
            identify yourself unless this is impractical or against the law.
          </p>
          <p>
            In some instances, if you do not provide us with certain PI we may
            not be able to provide you with the relevant product, Service or
            information (including fulfilling the Main Purposes)). This may have
            an effect on whether we can begin or continue a relationship with
            you or allow you to use our Services.
          </p>
          <H4>What disclosures will we make?</H4>
          <p>
            In general, we will only disclose your PI for the Main Purposes. PI
            may be communicated electronically or verbally by phone. We may also
            disclose your PI in other ways with your consent or as required or
            permitted by law.
          </p>
          <p>
            We may disclose your PI to our service providers and partners in
            order to assist us in providing the Services to you. We use a
            network of global third-party service providers in order to ensure
            that the Services we provide to you are of the best possible
            standard. Some of our service providers, or the services they
            provide (like cloud-based storage solutions), are based outside of
            the United Kingdom. This could include places like Europe, Asia and
            Australia. In order to protect your information, we take care where
            possible to work with service providers who we consider maintain
            acceptable standards of data security compliance, and we do our part
            to meet those standards as they apply to the MyStay ENGLISH.
          </p>
          <p>
            <strong>Disclosure to Other Users.</strong>&nbsp;As an integral part
            of providing the Main Purposes, we may disclose your profile
            information to other Student, Guests and Users. Photos and other
            profile information provided by you will be available to profiles
            that are matched with you. PI may be communicated to your matches
            electronically or verbally by phone.
          </p>
          <p>
            <strong>Disclosure By Law.</strong>&nbsp;You acknowledge and agree
            that we may disclose information you provide if required to do so by
            law, at the request of a third party, or if we, in our sole
            discretion, believe that disclosure is reasonable to (1) comply with
            the law, requests or orders from law enforcement, or any legal
            process (whether or not such disclosure is required by applicable
            law); (2) protect or defend the MyStay ENGLISH, or a third
            party&rsquo;s, rights or property; or (3) protect someone&rsquo;s
            health or safety, such as when harm or violence against any person
            (including you) is threatened.
          </p>
          <H3>Access, Confidentiality &amp; Security</H3>
          <H4>Is your PI confidential and secure?</H4>
          <p>
            We take all reasonable steps to keep the PI we hold secure and to
            ensure it is protected against misuse, loss, unauthorised access,
            modification or inappropriate disclosure. We may hold PI in both
            hard copy and electronic forms in secure systems accessible only to
            authorised personnel.
          </p>
          <H4>&lsquo;Cookies&rsquo; and related software</H4>
          <p>
            Our software may issue &lsquo;cookies&rsquo; (small text files) to
            your device when you access and use the Website and you will be
            asked to consent to this at the time (e.g. when you first visit our
            website). Cookies do not affect your privacy and security since a
            cookie cannot read data off your system or read cookie files created
            by other sites.
          </p>
          <p>
            Our Website uses cookies and other tracking and monitoring software
            to: distinguish our users from one another; collect standard
            Internet log information; and to collect visitor behaviour
            information. The information is used to track user interactions with
            the Website and allows us to provide you with a good experience when
            you access the Website, helps us to improve our Website, and allows
            us to compile statistical reports on Website visitors and Website
            activity.
          </p>
          <p>
            You can set your system not to accept cookies if you wish (for
            example by changing your browser settings so cookies are not
            accepted), however please note that some of our Website features may
            not function if you remove cookies from your system. For further
            general information about cookies please visit www.aboutcookies.org
            or&nbsp;
            <a href="http://www.allaboutcookies.org/">
              www.allaboutcookies.org
            </a>
            .
          </p>
          <H4>Links to other websites</H4>
          <p>
            Our Website may contain links to other websites. Unless the other
            website is one of our websites, we are not responsible for the
            privacy practices of the owners of those websites. We recommend that
            you read the privacy policy of any website that asks you to provide
            your PI.
          </p>
          <H4>Data Retention</H4>
          <p>
            Because many users tend to use our service at different points of
            their student or accommodation needs, we retain your personal
            information for continued service and convenience purposes until you
            advise us not to do so (See section &ldquo;Contacting us about
            privacy&rdquo; below). Notwithstanding the foregoing, we may retain
            personal information in order to comply with applicable laws, keep
            accurate records, resolve disputes, prevent fraud, enforce our Terms
            and Conditions of Service or other agreements, or for any other
            legally permissible purpose.
          </p>
          <H4>
            What will MyStay ENGLISH do if there is a &ldquo;Data Breach&rdquo;
          </H4>
          <p>
            The MyStay ENGLISH is committed to managing personal information in
            accordance with privacy principles. We have systems and processes in
            place to ensure your data is secured and appropriately managed,
            including in the event of a suspected or known data breach. Any
            questions about data security can directed to us via the contact
            details below.
          </p>
          <H3>Contacting us about privacy</H3>
          <p>
            You are responsible for ensuring that your PI is accurate, current
            and complete. If you have a registered account with us, you can
            update or change your PI and email preferences at any time via your
            account&rsquo;s profile page.
          </p>
          <p>
            You may ask us to provide you with the PI we hold about you, subject
            to certain limitations under law. For your protection, we may
            require you to confirm your identity before access to your PI is
            granted. In most cases, we can provide you with a summary of your PI
            free of charge. However, in some circumstances, reasonable costs may
            be charged to you in accessing your PI, subject always to the law.
          </p>
          <p>
            If you have a question or want to make a complaint about how we
            handle your PI, please contact us using the details below. We will
            review your complaint and will respond to you after we have
            carefully considered it. We may require further information from you
            in order to resolve any complaints. Requests may take up to 20
            working days to complete.
          </p>
          <p>We can be contacted via:</p>
          <ul>
            <li>
              Email:&nbsp;
              <a href="mailto:data-protection-office@mystayinternational.com">
                data-protection-office@mystayinternational.com
              </a>
            </li>
            <li>
              Post: MyStay International, PO Box 849, Toowong BC, QLD, 4066,
              Australia.
            </li>
          </ul>
          <H4>
            Contacting us about receiving a summary of your &ldquo;PI&rdquo;
          </H4>
          <p>
            If you wish for MSI to provide you with your currently held
            &ldquo;PI&rdquo; on your profile, across any of our services, please
            contact us as below:
          </p>
          <ul>
            <li>
              Email:&nbsp;
              <a href="mailto:data-protection-office@mystayinternational.com">
                data-protection-office@mystayinternational.com
              </a>
            </li>
            <li>
              Post: MyStay International, PO Box 849, Toowong BC, QLD, 4066,
              Australia.
            </li>
          </ul>
          <H4>
            Contacting us about removing your &ldquo;PI&rdquo; from the system
          </H4>
          <p>
            If you wish for MSI to remove your &ldquo;PI&rdquo; from our system,
            across any of our services, please contact us as below:
          </p>
          <ul>
            <li>
              Email:&nbsp;
              <a href="mailto:data-protection-office@mystayinternational.com">
                data-protection-office@mystayinternational.com
              </a>
            </li>
            <li>
              Post: MyStay International, PO Box 849, Toowong BC, QLD, 4066,
              Australia.
            </li>
          </ul>
          <p>
            Please note that such removal may impact on your ability to access
            or use the Services that we provide.
          </p>
          <H3>Changes to this policy</H3>
          <p>
            We may update this Privacy Policy from time to time and may notify
            you if there are significant changes. Therefore, it&rsquo;s
            important that you check this Privacy Policy from time to time to
            see if any changes have been made.
          </p>
          <H3>Acceptance of Privacy Statement</H3>
          <p>
            Your use of our website(s), including any dispute concerning
            privacy, is subject to this privacy statement and the applicable
            Terms and Conditions of Service. BY USING OUR WEBSITE, YOU ARE
            ACCEPTING THE PRACTICES SET OUT IN THIS PRIVACY STATEMENT AND THE
            APPLICABLE TERMS AND CONDITIONS OF SERVICE.
          </p>
          <p>
            If we decide to change our privacy policy, we will post those
            changes to this privacy statement page and any other places we deem
            appropriate so that you are aware of what information we collect,
            how we use it, and under what circumstances, if any, we disclose it.
            We reserve the right to modify this privacy statement at any time,
            so please review it frequently. If we make material changes to this
            policy, we will notify you here, or by other means, such as e-mail,
            at our discretion.
          </p>
          <p>
            Your continued use of any portion of our website following posting
            of the updated privacy statement will constitute your acceptance of
            the changes.
          </p>
          <H3>Effective Date</H3>
          <p>This privacy statement was last revised on September 3, 2018.</p>
        </Col>
      </ParagraphContainer>
    </Wrapper>
  </Layout>
);

export default PrivacyPolicyPage;
